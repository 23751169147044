import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const ImgElement = styled(props => <Img {...props}/>)`
  display: none;
  
  @media(min-width: 1000px) {
    display: block;
    height: 300px;
    width: 300px;
    border-radius: 100%;
    margin: auto;
  }
`;

const Image = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      datoCmsHeader {
        image {
          fluid(maxHeight: 400, maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `);

  return <ImgElement fluid={data.datoCmsHeader.image.fluid} />
}

export default Image
