import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Image from "./image"

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.5rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 1rem 10rem;
    flex-direction: row;
  }  
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const StyledH1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 4rem;
  margin-bottom: 1rem;
  
  @media(min-width: 800px) {
    margin-top: 6rem;
  }
`;

const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-weight: 500;
  margin-top: 1rem;
  max-width: 400px;
`;

const StyledLink = styled(props => <a {...props}/>)`
  color: #fff;
  font-size: 1.1rem;
  background-color: rgba(230, 50, 50, 0.86);
  border: none;
  border-radius: 25px;
  width: 10rem;
  line-height: 2.8rem;
  margin-top: 3rem;
  text-align: center;
  text-decoration: none;
`;

const HeaderRender = ({ data }) => {
  let button = ''
  if (data.datoCmsHeader.contactButtonSwitch) {
    button = <StyledLink href={data.datoCmsHeader.contactButtonAction}>{data.datoCmsHeader.contactButtonText}</StyledLink>;
  }

  return(
    <StyledHeader>
      <HeaderContainer>
        <StyledH1>{data.datoCmsHeader.title}</StyledH1>
        <Paragraph>{data.datoCmsHeader.quotation}</Paragraph>
        <Paragraph>{data.datoCmsHeader.quotationAuthor}</Paragraph>
        { button }
      </HeaderContainer>
      <ImageContainer>
        <Image/>
      </ImageContainer>
    </StyledHeader>
  );
}

export default function Header() {
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          datoCmsHeader {
            title
            quotation
            quotationAuthor
            contactButtonSwitch
            contactButtonText
            contactButtonAction
          }
        }
      `}
      render={data => <HeaderRender data={data}/>}
    />
  )
};