import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

const EducationSection = styled.section`
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  
  @media(min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  
  @media(min-width: 800px) {
    width: 33%;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const StyledH4 = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  
  @media(min-width: 800px) {
    margin: 1rem 0;
  }
`;

const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.3rem;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const EducationRender = ({ data }) => {
  return(
    <EducationSection>
      <StyledH3>{data.datoCmsEducation.title}</StyledH3>
      <StyledHr/>
      <EducationContainer>
        { data.datoCmsEducation.content.map(val => {
          return <Item key={val.title}>
            <StyledH4>{ val.title }</StyledH4>
            <Paragraph>{ val.content }</Paragraph>
          </Item>;
        })}
      </EducationContainer>
    </EducationSection>
  );
}

export default function Education() {
  return (
    <StaticQuery
      query={graphql`
        query EducationQuery {
          datoCmsEducation {
            title
            content {
              title
              content
            }
          }
        }
      `}
      render={data => <EducationRender data={data}/>}
    />
  )
};