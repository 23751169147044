import React from "react"
import styled from "styled-components"
import { graphql, Link, StaticQuery } from "gatsby"

const AboutMeSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.3rem;
`;

const StyledLink = styled(props => <Link {...props}/>)`
  color: #fff;
  font-size: 1.1rem;
  background-color: rgba(230, 50, 50, 0.86);
  border: none;
  border-radius: 25px;
  width: 10rem;
  line-height: 2.8rem;
  margin-top: 4rem;
  align-self: flex-end;
  text-align: center;
  text-decoration: none;
`;

const AboutMeRender = ({ data }) => {
  return(
    <AboutMeSection>
      <StyledH3>{data.datoCmsAboutMe.title}</StyledH3>
      <StyledHr/>
      <Paragraph>{data.datoCmsAboutMe.content}</Paragraph>
      <StyledLink to={"/o-mnie"}>Więcej</StyledLink>
    </AboutMeSection>
  );
}

export default function AboutMe() {
  return (
    <StaticQuery
      query={graphql`
        query AboutMeQuery {
          datoCmsAboutMe {
            title
            content
          }
        }
      `}
      render={data => <AboutMeRender data={data}/>}
    />
  )
};