import React from "react"

import './reset.css'
import './style.css'

import Navbar from "./navbar"
import Header from "./header"
import SEO from "./seo"
import AboutMe from "./aboutme"
import WorkExperience from "./work_experience"
import Education from "./education"
import Awards from "./awards"
import Opinions from "./opinions"
import Footer from "./footer"

const Layout = () => {
  return (
    <>
      <Navbar/>
      <Header/>
      <SEO/>
      <main>
        <AboutMe/>
        <WorkExperience/>
        <Education/>
        <Awards/>
        <Opinions/>
      </main>
      <Footer/>
    </>
  )
}

export default Layout
