import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

const WorkExperienceSection = styled.section`
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const WorkExperienceContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;

const StyledH4 = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  
  &::before {
    content: '';
    display: flex;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: rgba(230, 50, 50, 1);
    transform: translate(-30px);
    z-index: 1000;
  }
  
  @media(min-width: 800px) {
    margin: 1rem 0;
  }
`;

const Item = styled(props => <li {... props}/>)`
  padding: 20px;
  border-left: 4px solid rgba(230, 50, 50, 0.86);
  
  @media(min-width: 800px) {
    text-align: end;
    width: 50%;
    border-left: 0;
    border-right: 4px solid rgba(230, 50, 50, 0.86);
    
    ${StyledH4} {
      &:before {
        float: right;
        transform: translate(30px);
      }
    }
    
    &:nth-child(2n) {
      width: calc(50% + 4px);
      text-align: start;
      align-self: flex-end;
      border-right: 0;
      border-left: 4px solid rgba(230, 50, 50, 0.86);
      
      ${StyledH4} {
        &:before {
          float: left;
          transform: translate(-30px);
        }
      }
    }
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;



const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.3rem;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const WorkExperienceRender = ({ data }) => {
  return(
    <WorkExperienceSection>
      <StyledH3>{data.datoCmsWorkExperience.title}</StyledH3>
      <StyledHr/>
      <WorkExperienceContainer>
        { data.datoCmsWorkExperience.content.map(val => {
          return <Item key={ val.title }>
            <StyledH4>{ val.title }</StyledH4>
            <Paragraph>{ val.content }</Paragraph>
          </Item>;
        })}
      </WorkExperienceContainer>
    </WorkExperienceSection>
  );
}

export default function WorkExperience() {
  return (
    <StaticQuery
      query={graphql`
        query WorkExperienceQuery {
          datoCmsWorkExperience {
            title
            content {
              title
              content
            }
          }
        }
      `}
      render={data => <WorkExperienceRender data={data}/>}
    />
  )
};