import React, { useState } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

const OpinionsSection = styled.section`
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const OpinionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  // margin-top: 1rem;
`;

const Opinion = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem;
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.1rem;
  margin-top: 1rem;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 6%;
`;

const Button = styled.button`
  height: 25px;
  width: 25px;
  border: solid rgba(112, 112, 112, 1);
  border-width: 0 2px 2px 0;
  background-color: transparent;
  transform: ${props => (props.reversed ? 'rotate(135deg)' : 'rotate(-45deg)')};
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  
  &:focus {
    outline: 0;
  }
`;

const OpinionsRender = ({ data }) => {
  const [content, setContent] = useState(data.datoCmsOpinion.list[0].content)
  const [author, setAuthor] = useState(data.datoCmsOpinion.list[0].title)
  const [index, setIndex] = useState(0)

  const changeIndex = (reverse = false) => {
    let newIndex
    if (reverse) {
      if (index === 0) {
        newIndex = data.datoCmsOpinion.list.length - 1
      } else {
        newIndex = index - 1
      }
    } else {
      if (index === data.datoCmsOpinion.list.length - 1) {
        newIndex = 0
      } else {
        newIndex = index + 1
      }
    }

    setContent(data.datoCmsOpinion.list[newIndex].content)
    setAuthor(data.datoCmsOpinion.list[newIndex].title)
    setIndex(newIndex)
  }

  return(
    <OpinionsSection>
      <StyledH3>{data.datoCmsOpinion.title}</StyledH3>
      <StyledHr/>
      <OpinionsContainer>
        <ButtonWrapper>
          <Button onClick={() => changeIndex(true)} reversed={true}/>
        </ButtonWrapper>
        <Opinion>
          <p>{ content }</p>
          <Paragraph>- { author }</Paragraph>
        </Opinion>
        <ButtonWrapper>
          <Button onClick={() => changeIndex()}/>
        </ButtonWrapper>
      </OpinionsContainer>
    </OpinionsSection>
  );
}

export default function Opinions() {
  return (
    <StaticQuery
      query={graphql`
        query OpinionQuery {
          datoCmsOpinion {
            title
            list {
              title
              content
            }
          }
        }
      `}
      render={data => <OpinionsRender data={data}/>}
    />
  )
};